<template>
  <form @submit.prevent="formSubmit" class="form" novalidate spellcheck="false">
    <div class="form__item">
      <InputComponent
        v-model="form.cardNumber"
        title="Введите карту"
        :disabled="loading"
      />
    </div>
    <div class="form__error" v-if="error">{{ error }}</div>
    <div v-if="$store.state.config.card === 1" class="form__actions">
      <button
        type="button"
        @click.prevent="nextStep"
        class="btn-main"
      >
        Пропустить
      </button>
    </div>
    <div class="form__actions">
      <button @click.prevent="prevStep" type="button" class="btn-main">
        Назад
      </button>
      <button class="btn-main" type="submit">
        <LoadingIndicator v-if="loading"/>
        <template v-else>Далее</template>
      </button>
    </div>
  </form>
</template>

<script>
import LoadingIndicator from "../../../components/LoadingIndicator";
import InputComponent from "@/components/form/InputComponent";

export default {
  name: "CardBinding",
  props: {
    codes: Object,
  },
  data() {
    return {
      loading: false,
      error: null,
      form: {
        cardNumber: null,
        cvcCode: null,
      }
    }
  },
  beforeCreate() {
    if (this.$store.state.config.card === 0) {
      this.nextStep()
    } else {
      this.$store.dispatch('GET_CARD', {
        token: localStorage.getItem("auth-token")
      })
        .then(({ data }) => {
          if (data.data && data.data.length) {
            this.nextStep()
          }
        })
    }
  },
  created() {
    setTimeout(() => {
      if (this.$store.state.config.virtual_card === 1) {
        this.$store.dispatch("EMIT_VIRTUAL", {
          token: localStorage.getItem("auth-token")
        })
      }
    }, 1000)
    if (!this.$store.state.config.card) {
      this.nextStep()
    }
  },
  mounted() {
    setTimeout(() => {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("card")) {
        this.form.cardNumber = urlParams.get("card")
      }
    }, 0)
  },
  methods: {
    prevStep() {
      this.$emit("changeStep", this.codes.PASSWORD_CHANGE_CODE)
    },
    nextStep() {
      if (this.$store.state.config.virtual_card === 2) {
        this.$store.dispatch("EMIT_VIRTUAL", {
          token: localStorage.getItem("auth-token")
        })
      }
      if (this.$store.state.config.email) {
        this.$emit("changeStep", this.codes.EMAIL_CONFIRM_CODE)
      } else {
        this.$emit('changeStep', this.codes.QUESTIONS_CODE)
      }
    },
    formSubmit() {
      if (!this.loading) {
        this.loading = true
        /**
         * Сбор всех полей формы и закрепление в formData
         */
        this.$store.dispatch("SET_CARD", {
          data: { ...this.form },
          token: localStorage.getItem("auth-token")
        })
            .then(({ data }) => {
              if (data.result && data.result.state === "Success") {
                this.nextStep()
              } else if (data.result && data.result.state === "Error") {
                if (data.data && data.data.state === "RegistrationAlreadyCompleted") {
                  this.error = "Данный номер уже зарегистрирован."
                } else if (data.data && data.data.state === "PasswordRequired") {
                  this.error = "Если вы не меняли пароль, введите вместо пароля код подтверждения, отправленный при первой попытке регистрации"
                } else {
                  this.error = data.result.message
                }
              } else if (data.result && data.result.state === "ValidationError") {
                this.error = "Ошибка. Введите данные"
              }
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            });
      }
    }
  },
  components: {
    InputComponent,
    LoadingIndicator,
  }
}
</script>
