<template>
  <form @submit.prevent="formSubmit" class="form" novalidate spellcheck="false">
    <div class="form__item">
      <InputComponent
        v-model="form.login"
        :mask="'+7 (###) ###-##-##'"
        title="Номер телефона"
        :disabled="loading"
        @focus="focusHandler"
      />
    </div>
    <div v-if="registered" class="form__item">
      <InputComponent
        v-model="form.password"
        title="Пароль"
        :disabled="loading"
        type="password"
      />
    </div>
    <div v-if="error || timer > 0 && registered" class="form__errors">
      <template v-if="error">
        <div class="form__error">{{ error }}</div>
        <div class="form__error" v-if="!registrationAlreadyCompleted">
          Если сообщение утеряно, обратитесь в контакт-центр по номеру: <br>
          <a :href="`tel:${$store.state.config.contact_center}`">
            {{ $store.state.config.contact_center | vMask("+# (###) ###-##-##") }}
          </a>
        </div>
      </template>
      <template v-if="timer > 0 && registered">
        <div class="form__message">Код подтверждения отправлен</div>
        <div class="form__message">Повторная отправка кода будет доступна через: {{ timer }} секунд</div>
      </template>
    </div>
    <div class="form__actions">
<!--      <button-->
<!--        v-if="registered && !isPasswordChanged"-->
<!--        @click="resendCode"-->
<!--        class="btn-main btn-auto"-->
<!--        type="button"-->
<!--        :disabled="timer > 0"-->
<!--      >-->
<!--        <template>Отправить код еще раз</template>-->
<!--      </button>-->
      <button class="btn-main" type="submit">
        <LoadingIndicator v-if="loading"/>
        <template v-else>Далее</template>
      </button>
    </div>
  </form>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator";
import InputComponent from "@/components/form/InputComponent";

export default {
  name: "BeginRegistration",
  props: {
    codes: Object,
  },
  data() {
    return {
      loading: false,
      error: null,
      timer: 0,
      interval: null,
      registered: false,
      needPassword: false,
      registrationAlreadyCompleted: false,
      form: {
        login: null,
        password: null,
      }
    }
  },
  methods: {
    focusHandler() {
      if (!this.form.login) {
        this.form.login += " "
      }
    },
    resendCode() {
      this.$store.dispatch("SEND_CONFIRM_CODE", {
        data: { phoneNumber: this.form.login },
        token: localStorage.getItem("auth-token")
      })
        .then(({ data }) => {
          if (data.result && data.result.state === "Success") {
            this.timer = this.$store.state.config.code_timer
            this.countdown()
          } else if (data.result && data.result.state === "Error") {
            this.error = data.result.message
          }
        })
    },
    countdown() {
      clearInterval(this.interval)
      this.timer = this.$store.state.config.code_timer
      this.interval = setInterval(() => {
        if (this.timer <= 0) {
          clearInterval(this.interval)
          return
        }
        this.timer -= 1
      }, 1000)
    },
    formSubmit() {
      if (!this.loading) {
        this.loading = true
        this.registrationAlreadyCompleted = false;
        /**
         * Сбор всех полей формы и закрепление в formData
         */
        this.$store.dispatch("BEGIN_REGISTRATION", {
          // login: this.form.login ? "7" + this.form.login.replace(/[^\d]/g, '') : null,
          login: this.form.login ? this.form.login.replace(/[^\d]/g, '') : null,
          password: this.form.password,
        })
            .then(({data}) => {
              // если введенный номер еще не зарегистрирован, то на него отправляется сообщение
              let token = data && data.data && data.data.authToken ? data.data.authToken : null
              if (data.result && data.result.state === "Success" && token) {
                // сохраняем полученный токен
                this.$store.dispatch('setAuthToken', { token: token })
                // получение шагов регистрации
                this.$store.dispatch('REGISTRATION_ACTIONS', { token: token }).then(() => {
                  if (this.form.password) {
                    // проверка, какой шаг пропущен и возврат на него
                    if (this.$store.state.registration_actions.find(item => item.userActionType === "PasswordRequired").isDone) {
                      if (this.$store.state.registration_actions.find(item => item.userActionType === "Questions").isDone) {
                        this.$emit('changeStep', this.codes.FINISH_CODE)
                      } else {
                        this.$store.dispatch('GET_QUESTIONS', {
                          token: localStorage.getItem("auth-token")
                        }).then(() => {
                          if (this.$store.state.config.card) {
                            this.$emit("changeStep", this.codes.CARD_BINDING_CODE)
                          } else if (this.$store.state.config.email) {
                            this.$emit("changeStep", this.codes.EMAIL_CONFIRM_CODE)
                          } else {
                            this.$emit("changeStep", this.codes.QUESTIONS_CODE)
                          }
                        })
                      }
                    } else {
                      this.$emit("changeStep", this.codes.PASSWORD_CHANGE_CODE)
                    }
                  } else {
                    this.$store.state.user.phoneNumber = this.form.login.replace(/[^\d]/g, '')
                    this.$emit("changeStep", this.codes.PHONE_CONFIRM_CODE)
                  }
                })
              } else if (data.result && data.result.state === "Error") {
                // сохраняем номер телефона
                this.$store.state.user.phoneNumber = this.form.login.replace(/[^\d]/g, '')
                if (data.data && data.data.state === "RegistrationAlreadyCompleted") {
                  this.$router.push({ name: "bonuscards" })
                  this.error = "Данный номер уже зарегистрирован."
                  this.registrationAlreadyCompleted = true
                } else if (data.data && data.data.state === "PasswordRequired") {
                  this.error = "Если вы не меняли пароль, введите вместо пароля код подтверждения, отправленный при первой попытке регистрации"
                  this.registered = true
                } else {
                  // this.error = data.result.message
                  this.error = "Введены неправильные данные"
                }
              } else if (data.result && data.result.state === "ValidationError") {
                this.error = "Ошибка. Введите данные"
              }
              this.loading = false
            })
            .catch(() => {
            });
      }
    }
  },
  components: {
    InputComponent,
    LoadingIndicator,
  }
}
</script>
