<template>
  <div class="finish__text">
    <h4>{{ $store.state.config.finish_text }}</h4>
  </div>
</template>

<script>
export default {
  name: "FinishRegistration",
  mounted() {
    if (this.$store.state.config.url_redirect) {
      setTimeout(() => {
        window.location = this.$store.state.config.url_redirect
      }, this.$store.state.config.redirect_timer)
    }
  }
}
</script>

<style lang="stylus">
.finish__text {
  text-align center
}
</style>
